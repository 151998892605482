@use 'abstract' as *;

.mat-form-field,
.mat-input-element {
    color: white;
}

.mat-form-field.mat-focused .mat-form-field-label,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $mat-accent;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $mat-accent;
}
